@tailwind base;
@tailwind components;
@tailwind utilities;

$input-height: 30px;

html {
  height: 100%;
  font-size: 14px;
}

body {
  min-height: 100%;
  background-color: #f5f5f5;
  color: #383838;
}

#root {
  min-height: 100vh;
}

/* Colors */
.color-primary {
  color: #ee8595 !important;
}

.divide-dot {
  border: 1px dashed #c0c0c0;
}

/* CUSTOMIZE MUI */
.MuiOutlinedInput-notchedOutline {
  border-width: 0 !important;
}

.form-control {
  .MuiOutlinedInput-input {
    background-color: rgba(246, 241, 242, 0.5) !important;
    color: #383838 !important;
  }

  .MuiOutlinedInput-root {
    &.Mui-error {
      .MuiOutlinedInput-notchedOutline {
        border-width: 1px !important;
      }
    }
  }
}

.MuiFormHelperText-root {
  margin: 0 !important;
  margin-top: 0.5rem !important;
}

.MuiButton-sizeMedium {
  height: 40px;
}

.custom-select {
  .MuiSelect-select {
    padding: 13px 14px !important;
  }
}

.custom-input {
  .MuiOutlinedInput-input {
    height: $input-height  !important;
  }
}

.backgrounded-input {
  .MuiOutlinedInput-root {
    background: rgba(246, 241, 242, 0.5) !important;
    border-radius: 5px !important;
  }
}

.custom-button {
  padding: 13px 23px !important;
}

.custom-pagination {
  .MuiInputBase-root {
    background-color: #EBEAEA;
    border-radius: 5px !important;
    height: 30px !important;
  }
}

.button-group-custom {
  .MuiButton-root {
    height: 50px !important;
    min-width: 100px !important;
    background: #ffffff !important;

    &:first-child {
      border-radius: 10px 0px 0px 10px !important;
    }

    &:not(:last-of-type) {
      border-right: 1px solid #e2dedf !important;
    }

    &:last-child {
      border-radius: 0;
    }

    svg {
      path {
        fill: #c0c0c0;
      }
    }

    span {
      font-weight: 500;
      font-size: 14px;
      margin-left: 8px;
      color: #c0c0c0;
    }

    &.selected {
      background: #ee8595 !important;

      svg {
        path {
          fill: #ffffff;
        }
      }

      span {
        color: #ffffff;
      }
    }
  }
}

.bordered-input {
  border: 1px solid #c0c0c0;

  .MuiOutlinedInput-notchedOutline {
    opacity: 0 !important;
  }
}